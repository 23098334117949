import {
  CREATE_WITHDRAWAL_STEP_ONE,
  GET_ACCOUNTING_DASHBOARD,
  GET_ACCOUNTING_MERCHANT_BALANCE,
  GET_ACCOUNTING_MERCHANT_BOOKING,
  GET_ACCOUNTING_PAYOUT_HISTORY,
  GET_ACCOUNTING_WITHDRAWAL_LIST,
  GET_APPROVED_WITHDRAWAL,
  GET_MERCHANT_DASHBOARD,
  GET_MERCHANT_WITHDRAWAL_LIST,
  GET_PAYOUT_SUMMARIES,
  GET_WITHDRAWAL_DETAIL,
  WithdrawalActionType,
  WithdrawalState
} from "./types";
import { defaultList } from "../../../utils/helper";

const initialState: WithdrawalState = {
  merchantDashboard: {
    total_amount: 0,
    total_balance: 0,
    withdrawable: 0,
    date: null,
    withdrawable_date: null
  },
  accountingDashboard: {
    total_pending_requests: 0,
    total_processed: 0,
    total_remaining: 0,
    total_payment_gateway: 0,
    total_payout_gateway: 0,
    total_commission: 0,
    date: null
  },
  merchantWithdrawal: defaultList,
  accountingWithdrawal: defaultList,
  withdrawalDetail: null,
  createWithdrawalStepOne: null,
  accountingMerchantBalance: defaultList,
  accountingMerchantBooking: defaultList,
  accountingPayoutHistory: defaultList,
  approvedWithdrawal: [],
  payoutSummaries: null
}

export default function (state= initialState, action: WithdrawalActionType): WithdrawalState {
  switch (action.type) {
    case GET_ACCOUNTING_DASHBOARD:
      return {
        ...state,
        accountingDashboard: action.payload
      }
    case GET_MERCHANT_DASHBOARD:
      return {
        ...state,
        merchantDashboard: action.payload
      }
    case GET_ACCOUNTING_WITHDRAWAL_LIST:
      return {
        ...state,
        accountingWithdrawal: action.payload
      }
    case GET_MERCHANT_WITHDRAWAL_LIST:
      return {
        ...state,
        merchantWithdrawal: action.payload
      }
    case GET_WITHDRAWAL_DETAIL:
      return {
        ...state,
        withdrawalDetail: action.payload
      }
    case CREATE_WITHDRAWAL_STEP_ONE:
      return {
        ...state,
        createWithdrawalStepOne: action.payload
      }
    case GET_ACCOUNTING_MERCHANT_BALANCE:
      return {
        ...state,
        accountingMerchantBalance: action.payload
      }
    case GET_ACCOUNTING_MERCHANT_BOOKING:
      return {
        ...state,
        accountingMerchantBooking: action.payload
      }
    case GET_ACCOUNTING_PAYOUT_HISTORY:
      return {
        ...state,
        accountingPayoutHistory: action.payload
      }
    case GET_APPROVED_WITHDRAWAL:
      return {
        ...state,
        approvedWithdrawal: action.payload
      }
    case GET_PAYOUT_SUMMARIES:
      return {
        ...state,
        payoutSummaries: action.payload
      }
    default:
      return state
  }
}